<template>
  <div>
    <div class="chooseroombox mb-2" v-if="device === 'mobile'">
      <button class="js-del-row" v-if="index > 0" @click="remove">
        <i class="far fa-window-close"></i>
      </button>
      <h2>{{$t("product-page.room")}} {{ index + 1 }} : {{ $t("product-page.choose-room") }}</h2>
      <!-- <h6 class="mt-0" style="color:red;"><strong>{{$t("product-page.available-rooms")}}: {{ availableRooms }}</strong></h6> -->
      <div class="row row-0 mx-n2">
        <div class="col-6">
          <div class="form-group">
            <b-form-select
              v-model="currentTypeCode"
              :options="optionByRoomTypes"
            ></b-form-select>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <b-form-select
              v-model="countOfBaby"
              :options="babies"
            ></b-form-select>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <b-form-select
              v-model="currentBasis"
              :options="optionBasis"
            ></b-form-select>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <b-form-select
              v-model="currentClassCode"
              :options="optionByRoomClasses"
            ></b-form-select>
          </div>
        </div>
        <div class="col-6 m-auto text-center" v-if="isOdysseySite && isFCLAgent">
          <div class="form-group">
            {{ $t('search-result.discount-calculator') }}
          </div>
        </div>
        <div class="col-6" v-if="isOdysseySite && isFCLAgent">
          <div class="form-group">
            <b-form-select v-model="manualDiscount">
              <b-form-select-option :value="inx" v-for="(n, inx) in 21" :key="inx">{{`${inx}%`}}</b-form-select-option>
            </b-form-select>
          </div>
        </div>
        <div class="col-12 mt-1" v-if="showBabyNotice">
          <p>{{ $t('product-page.baby-addition-warning') }}</p>
        </div>
        <!-- <div class="col-4">
        </div> -->
        <div class="col-12 ptag">
          <p class="pl-2 mb-1">
            {{ $t("product-page.price-room") }}
          </p>
          <p class="mb-2" v-if="countOfBaby === 0">({{ $t("product-page.no-babies") }})</p>
          <p class="mb-2" v-if="countOfBaby && countOfBaby === 1">({{ $t("product-page.infant") }} {{ countOfBaby }})</p>
          <p class="mb-2" v-if="countOfBaby && countOfBaby > 1">({{ $t("product-page.infants") }} {{ countOfBaby }})</p>
          <p class="mb-2 p-price" v-if="isOdysseySite">
            <strong class="mx-1">₪{{ netPrice }}</strong>
            {{ $t('search-result.full-price') }}:<span class="mx-1">₪{{ currentPrice }}</span>
          </p>
          <p class="mb-2 p-price" v-else>
            <strong class="mx-3">₪{{ currentPrice }}</strong>
            <s>₪{{ oldPrice }}</s>
          </p>
        </div>
      </div>
    </div>
    <div class="card-body" v-if="device === 'desktop'">
      <h5>
        <strong>{{ $t("product-page.room") }} {{ $t(`product-page.ordered-number[${index}]`)}} : {{ roomClassName }}, {{ personString }}</strong>
      </h5>
      <!-- <h5 style="color:red;"><strong>{{$t("product-page.available-rooms")}}: {{ availableRooms }}</strong></h5> -->
      <table class="table">
        <thead>
          <tr>
            <th colspan="2">{{ $t("product-page.room-type") }}</th>
            <!-- <th>{{ $t("product-page.babies") }}</th> -->
            <th>{{ $t("product-page.room-class") }}</th>
            <th>{{ $t("product-page.basis") }}</th>
            <!-- <th>{{ $t("product-page.price") }}</th> -->
          </tr>
        </thead>
        <tr>
          <td colspan="2">
            <b-form-select
              v-model="currentTypeCode"
              :options="optionByRoomTypes"
            ></b-form-select>
          </td>
          <!-- <td>
            <b-form-select
              v-model="countOfBaby"
              :options="babies"
            ></b-form-select>
          </td> -->
          <td>
            <b-form-select
              v-model="currentClassCode"
              :options="optionByRoomClasses"
            ></b-form-select>
          </td>
          <td>
            <b-form-select
              v-model="currentBasis"
              :options="optionBasis"
            ></b-form-select>
          </td>
          <!-- <td>
            <b-spinner v-if="priceLoading"></b-spinner>
            <div v-else>
              <span>₪{{ currentPrice }}</span
              ><br /><s>₪{{ oldPrice }}</s>
            </div>
          </td> -->
        </tr>
        <tr>
          <td class="align-middle" v-if="isOdysseySite && isFCLAgent">{{ $t('search-result.discount-calculator') }}:</td>
          <td v-if="isOdysseySite && isFCLAgent">
            <b-form-select v-model="manualDiscount">
              <b-form-select-option :value="inx" v-for="(n, inx) in 21" :key="inx">{{`${inx}%`}}</b-form-select-option>
            </b-form-select>
          </td>
          <td class="align-middle">{{ $t('product-page.babies') }}:</td>
          <td><b-form-select v-model="countOfBaby" :options="babies"></b-form-select></td>
        </tr>
        <tr v-if="showBabyNotice">
          <td colspan="3">
            <p>{{ $t('product-page.baby-addition-warning') }}</p>
          </td>
        </tr>
      </table>

      <button class="js-del-row" v-if="index > 0" @click="remove">
        <i class="far fa-window-close"></i>
      </button>
    </div>
  </div>
</template>

<script>
import { BFormSelect, BFormSelectOption } from 'bootstrap-vue';
import TemplatePriceOne from './TemplatePriceOne';

export default {
  mixins: [TemplatePriceOne],
  components: {
    BFormSelect,
    BFormSelectOption,
    // BSpinner,
  },
};
</script>

<style scoped>
s {
  opacity: 0.5;
}
.ptag{
  text-align: center;
}
.p-price {
  /*display: inline-flex;*/
  align-items: baseline;
}
.table > thead > tr > th {
  padding: 12px 3px;
}
.table td {
  padding: 0.75rem 0.1rem;
  vertical-align: middle;
  text-align: center;
}
.custom-select {
  padding: 0.375rem 1.55rem 0.375rem 0.05rem;
}
@media (max-width: 479px) {
  .card-body {
    padding: 0.25rem;
  }
  .flight-details-leftbox .table > tbody > tr > td,
  .flight-details-leftbox .table > tbody > tr > th,
  .flight-details-leftbox .table > tfoot > tr > td,
  .flight-details-leftbox .table > tfoot > tr > th,
  .flight-details-leftbox .table > thead > tr > td,
  .flight-details-leftbox .table > thead > tr > th {
    padding: 4px;
  }
}
.form-check-input {
  right: -15px;
}
</style>
